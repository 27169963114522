import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/views/service/language.service';
import { CookieService } from 'ngx-cookie-service'; 
import { AuthorizationService } from 'src/app/shared/services/auth/authorization.service';
import { Subscription } from 'rxjs';
import { CountryCodeService } from 'src/app/views/service/country-code.service';
import { ToastsService } from '../../services/toast-service';
@Component({
  selector: 'app-top-banner',
  templateUrl: './top-banner.component.html',
  styleUrls: ['./top-banner.component.scss']
})
export class TopBannerComponent implements OnInit {
  public userDetails:any;
  userData:Subscription;
  public show:boolean = false;
    lang: string = '';
  currentLang = 'sv';
    availableLangs = [{
        name: 'English',
        code: 'en',
        lngCode: 'en-US',
        key: 'c=en-US|uic=en-US'
    }, {
        name: 'Swedish',
        code: 'sv',
        lngCode: 'sv-SE',
        key: 'c=sv-SE|uic=sv-SE'
    }
        , {
        name: 'Finnish',
        code: 'fi',
        lngCode: 'fi-FI',
        key: 'c=fi-FI|uic=fi-FI'
    }];

  constructor(
   public translate: TranslateService,
    private languageService:LanguageService,
    private authorizationService: AuthorizationService, 
    private cookieService:CookieService,
    private countrycodeService: CountryCodeService,
    private toastservice: ToastsService
    ) { 
      try {

        this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));

        this.userData = this.authorizationService.getuserLoggedSubject().subscribe((userDetails) => {
          this.userDetails = userDetails;
        });
      }
      catch ( err ) {
        this.toastservice.errorSnackBar( {
            msg: [this.translate.instant("genericErrorMessage") + " (UI-0703001) - " + err],
            type: 'error'
        });
    
    }
    
    
   }

  ngOnInit() { 
      try {

        this.lang = this.cookieService.get('.AspNetCore.Culture');
        if (this.lang) {
            // this.currentLang=this.lang;
        }
        else {
          this.currentLang = this.countrycodeService.getCountryCode() =='se' ? 'sv' : 'fi';
        }
      
        this.availableLangs.map((item, val) => {
            if (this.lang === item.key) {
                // this.setCookie(item.lngCode);
                this.currentLang = item.code;
            }
        })
        this.languageService.setLanguage(this.currentLang);
        this.userDetails = JSON.parse(sessionStorage.getItem('userDetails'));
      }
      catch( err ) {

        this.toastservice.errorSnackBar( {
          msg: [this.translate.instant("genericErrorMessage") + " (UI-0703002) - " + err],
          type: 'error'
        });
      }
      
    }

    setCookie(lng) {
        this.languageService.setLanguageCookies(lng).subscribe(data => {
            
        })
    }

  setLang(selectedLang) {
    try {
    
    this.currentLang = selectedLang.code;
    this.setCookie(selectedLang.lngCode)
    this.languageService.setLanguage(this.currentLang);
    this.translate.addLangs(['sv', 'en', 'fi'])
    this.translate.setDefaultLang(this.currentLang);
    this.translate.use(this.currentLang);

  }
  catch( err ) {

      this.toastservice.errorSnackBar( {
        msg: [this.translate.instant("genericErrorMessage") + " (UI-0703003) - " + err],
        type: 'error'
      });
    }
    
  }

  //navbar menu togoole
  navbarMenu()
  {
  
    this.show = !this.show;
    
  }


}
