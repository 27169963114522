import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translt'
})
export class TransltPipe implements PipeTransform {

  constructor(private translate: TranslateService,){}

  transform(value: string): any {

    if (value == "Capital") {
      return this.translate.instant("regCaseinvoiceTypeCapital");
    }
    else if (value == "Interest") {
      return this.translate.instant("regCaseinvoiceTypeInterest");
    }
    else if (value == "Fees") {
      return this.translate.instant("regCaseinvoiceTypeFees")
    }
    
  }

}
