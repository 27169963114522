import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'tansltType'
})
export class TansltTypePipe implements PipeTransform {

  constructor(private translate: TranslateService,){}

  transform(value: string): string {
    
    if (value == "REF_FIN7") {
      return this.translate.instant("regCaseinterestTypeREF_FIN7");
    }
    else if (value == "REF_FIN8") {
      return this.translate.instant("regCaseinterestTypeREF_FIN8");
    }
    else if (value == "FIXED") {
      return this.translate.instant("regCaseinterestTypeFIXED");
    }
    else if (value == "ÖGR") {
      return this.translate.instant("regCaseinterestTypeOGR");
    }
    else if (value == "FA") {
      return this.translate.instant("regCaseinterestTypeFA");
    }
  
  }

}
